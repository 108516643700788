import axios from 'axios';

const namespaced = true

const state = {
    posts: [],
    fetchActive: true
}

const actions = {
    async getAll({dispatch}) {
        return await dispatch('database/getItems', {table: 'news'}, {root: true})
    },
    async fetchFromHttp({dispatch}, id) {
        let posts = (await axios.get('https://mantel-api.mobile.documedias.systems/v2/conferences/' + id + '/news')).data;
        await dispatch('database/upsertItems', {items: posts, table: 'news'}, {root: true});
        return posts;
    },

    async fetchNews({dispatch, state, rootState}, id) {
        try {
            let _posts = (await dispatch('fetchFromHttp', id)).sort((a, b) => {
                return new Date(b.publish_at) - new Date(a.publish_at);
            });
            state.posts = [];
            let oldTime = await dispatch('personalDB/getItem', 'timeStamps_' + rootState.currentConference.currentConference, {root: true});
            let time = await dispatch('personalDB/setTime', {
                name: 'timeStamps_' + rootState.currentConference.currentConference,
                item: oldTime
            }, {root: true});
            _posts.map(post => {
                post.publish_at = post.publish_at.indexOf('Z') === -1 ? new Date(post.publish_at + '+00:00') : new Date(post.publish_at);
                if ((new Date(post.publish_at)) < (new Date())) {
                    post.unread = (new Date(post.publish_at)) > time;
                    state.posts.push(post);
                }
            });
            state.fetchActive = false;
        } catch (err) {
            console.log('EERRORR', err)
            try {
                let _posts = (await dispatch('getAll')).sort((a, b) => {
                    return new Date(b.publish_at) - new Date(a.publish_at);
                });

                let oldTime = await dispatch('personalDB/getItem', 'timeStamps_' + rootState.currentConference.currentConference, {root: true});
                let time = await dispatch('personalDB/setTime', {
                    name: 'timeStamps_' + rootState.currentConference.currentConference,
                    item: oldTime
                }, {root: true});
                _posts.map(post => {
                    post.publish_at = post.publish_at.indexOf('Z') === -1 ? new Date(post.publish_at + '+00:00') : new Date(post.publish_at);
                    if ((new Date(post.publish_at)) < (new Date())) {
                        post.unread = (new Date(post.publish_at)) > time;
                        state.posts.push(post);
                    }
                });
                state.fetchActive = false;
            } catch (err) {
                state.posts;
            }
        }
    },

    getNewsNumber() {
        return state.newsNumber;
    }
}
const mutations = {
    addNewsNumber(state, newsNumber) {
        state.newsNumber = newsNumber + state.newsNumber;
    },
    resetNewsNumber(state, newsNumber) {
        state.newsNumber = newsNumber;
    }
}
const getters = {}

export const news = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
