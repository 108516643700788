const namespaced = true

const state = {}

const actions = {
    async getAllTypes({dispatch}) {
        return await dispatch('database/getItems', {table: 'sessiontypes'}, {root: true})
    },
    async getType({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'sessiontypes', id: id}, {root: true})
    },
    async getTag({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'tags', id: id}, {root: true})
    },
    async getSessionTypes({dispatch}) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links'], {root: true});
        let types = [];
        for (let i = 0; i < data['sessiontypes'].length; i++) {
            let type = data['sessiontypes'][i];
            type.count = 0;

            for (let k = 0; k < data['session_sessiontype_links'].length; k++) {
                let sess_type_link = data['session_sessiontype_links'][k];
                if (type.id == sess_type_link.sessiontype_id) {
                    for (let j = 0; j < data['sessions'].length; j++) {
                        let session = data['sessions'][j]
                        if (session.id === sess_type_link.session_id) {
                            type.count++;
                        }
                    }
                }
            }
            types.push(type);
        }
        return types;
    },

    //V2-ready
    async getSessionsByType({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links'], {root: true});
        let sessionsToReturn = [];
        //sesstype-block
        let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.sessiontype_id == id);

        let type = data['sessiontypes'].filter(type => type.id == id)[0];
        sesstype_links.forEach(sesstype_link => {
            let sessions = data['sessions'].filter(session => session.id == sesstype_link.session_id);

            sessions.forEach(session => {


                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
                let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
                let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
                session.location = location;
                session.location_order = location ? location.order : 0;
                session.chair = []
                session.speakers = [];

                let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                if (sess_pres_links.length > 0) {
                    sess_pres_links.forEach(sess_pres_link => {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                        presroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.speakers).indexOf(person.display_name) == -1) {
                                    session.speakers.push(person);
                                }
                            }
                        })
                    })
                } else {
                    let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                    sessionroles.forEach(role => {
                        let person = data['persons'].filter(person => person.id == role.person_id)[0];
                        if (person) {
                            person.function = role.function;
                            if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                session.chair.push(person);
                            }
                        }
                    })
                }
                sessionsToReturn.push(session);
            })
        })
        return sessionsToReturn;
    },
    async getSessionsByTag({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links', 'person_roles', 'persons', 'session_presentation_links', 'tags', 'session_tag_links'], {root: true});
        let sessionsToReturn = [];

        let taglinks = data['session_tag_links'].filter(sesstaglinks => sesstaglinks.tag_id == id);

        taglinks.forEach(taglink => {
            let sessions = data['sessions'].filter(session => session.id == taglink.session_id);

            sessions.forEach(session => {
                //sesstype-block
                let sesstype_link = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id)[0];

                let type = data['sessiontypes'].filter(type => type.id == sesstype_link.sessiontype_id)[0];

                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
                let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
                let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
                session.location = location;
                session.location_order = location ? location.order : 0;
                session.chair = []
                session.speakers = [];

                let sess_pres_links = data['session_presentation_links'].filter(sess_pres_link => sess_pres_link.session_id == session.id);
                if (sess_pres_links.length > 0) {
                    sess_pres_links.forEach(sess_pres_link => {
                        let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                        let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == sess_pres_link.presentation_id)
                        sessionroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                    session.chair.push(person);
                                }
                            }
                        })
                        presroles.forEach(role => {
                            let person = data['persons'].filter(person => person.id == role.person_id)[0];
                            if (person) {
                                person.function = role.function;
                                if (JSON.stringify(session.speakers).indexOf(person.display_name) == -1) {
                                    session.speakers.push(person);
                                }
                            }
                        })
                    })
                } else {
                    let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                    sessionroles.forEach(role => {
                        let person = data['persons'].filter(person => person.id == role.person_id)[0];
                        if (person) {
                            person.function = role.function;
                            if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                                session.chair.push(person);
                            }
                        }
                    })
                }
                sessionsToReturn.push(session);

            })

        })

        return sessionsToReturn;
    }

}
const mutations = {}
const getters = {}

export const sessiontypes = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
